import * as React from 'react';
import Box from '@mui/material/Box';
import {CircularProgress} from "@mui/material";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {
    setNewWordpressIntegrationService,
    validateWordpressCredentialsService
} from "../../../services/agent-post-ai.service";
import {getUserWithEmailThunk} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios"

const steps = ['Connect Wordpress'];

const WordpressLogin = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [loading, setLoading] = React.useState(false);
    const [ref, setRef] = React.useState({});
    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const INITIAL_FROM_STATE = {
        blogUrl: '',
        username: '',
        password: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        //topic: Yup.mixed().required('The Topic is required'),
        blogUrl: Yup.string().url('Blog URL must be a valid url').required('Blog URL is required'),
        // username: Yup.mixed().required('Username is required'),
        // password: Yup.mixed().required('Password is required')
    });
    const onSubmitForm = async (values) => {
        (function() {
            /*const root = document.querySelector('#root');
            const stage1 = root.querySelector('.stage-1');
            const stage2 = root.querySelector('.stage-2');*/

            // If there's no GET string, then no credentials have been passed back. Let's get them.
            if (!window.location.href.includes('?')) {
                // Stage 1: Get the WordPress Site URL, Validate the REST API, and Send to the Authentication Flow
                // const urlInput = stage1.querySelector('input[type=url]');
                const urlInput = values.blogUrl;

                /*stage1.querySelector('input[type=submit]').addEventListener('click', function(event) {
                    event.preventDefault();*/

                    if (urlInput) {
                        let linky = urlInput + '?rest_route=/';
                        console.log("Attempting to query", linky)
                        /*let paragraph = document.createElement('p');
                        paragraph.innerHTML = `Attempting to query <a href="${linky}">${linky}</a>…`;
                        stage1.appendChild(paragraph);*/

                        // fetch(linky)
                        /*axios.get(linky, {
                            headers: {
                                "access-control-allow-origin": process.env.REACT_APP_REDIRECT_URI
                            }
                        })
                            // .then(response => response.json())
                            .then(response => {
                                const {data} = response*/
                          const data = urlInput
                                // If it doesn't look like a WordPress REST API response, bail!
                                /*if (!data.url) {
                                    console.error(`Error: ${linky} does not seem to be a WordPress REST API.`)
                                    toastr.error("Error:", `${linky} does not seem to be a WordPress REST API.`)
                                    //let errorParagraph = document.createElement('p');
                                    //errorParagraph.innerHTML = `Error: ${linky} does not seem to be a WordPress REST API.`;
                                    //stage1.appendChild(errorParagraph);
                                    return;
                                }*/

                                console.log(data);
                                // Yay, we found WordPress! Report back to the user.
                                console.log("Success found:", data)
                                /*let successMessage = `<p>Success: Found <strong>${data.name}</strong>`;
                                successMessage += data.description ? `: <em>${data.description}</em></p>` : '</p>';
                                stage1.insertAdjacentHTML('beforeend', successMessage);*/

                                // If no Application Passwords, bail.
                                if (false) {
                                    console.log("Looks like Application Passwords is not available!")
                                    toastr.warning("Warning", "Looks like Application Passwords is not available!")
                                    /*stage1.insertAdjacentHTML('beforeend', '<p>Looks like Application Passwords is not available!</p>');
                                    urlInput.focus();*/
                                    return;
                                }

                                // Yay, we have Application Passwords!
                                const finalUrl = data + "/wp-admin/authorize-application.php";
                                const authorizationLinky = finalUrl +
                                    '?' + new URLSearchParams({
                                        app_name: 'Test Application',
                                        success_url: `${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/wordpress/newIntegrationAppPass/?user_id=${props.user.id}&site_url=` + linky.split('?')[0]
                                    }).toString();
                                console.log("auth with ", authorizationLinky)
                                window.location.href = authorizationLinky;

                                // Display the link for the user to authenticate.
                                // stage1.insertAdjacentHTML('beforeend', `<p>Would you like to <a href="${authorizationLinky}">authenticate with that site</a>?</p>`);
                            /*/})
                            .catch(error => {
                                console.error('Error:', error);
                                // stage1.insertAdjacentHTML('beforeend', `<p>Error: ${linky} does not seem to validate as a URL.</p>`);
                            });*/
                    } else {
                        // stage1.insertAdjacentHTML('beforeend', `<p>Error: ${urlInput} does not seem to validate as a URL.</p>`);
                        console.log(urlInput, "does not valid")
                        toastr.error("Error:", `${urlInput} does not seem to validate as a URL`)

                    }

            } /*else {
                stage1.style.display = 'none';
                const credentials = new URLSearchParams(window.location.search);

                stage2.insertAdjacentHTML('beforeend', `<p>Got credentials! user: <kbd>${credentials.get('user_login')}</kbd> pass: <kbd>${credentials.get('password')}</kbd></p>`);

                const apiRoot = `${credentials.get('site_url')}?rest_route=/`;

                stage2.insertAdjacentHTML('beforeend', '<p>Making authenticated request to site to list users…</p>');

                fetch(apiRoot + 'wp/v2/users&context=edit', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa(`${credentials.get('user_login')}:${credentials.get('password')}`)
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        stage2.insertAdjacentHTML('beforeend', `<p>Found ${data.length} user(s):</p>`);
                        data.forEach(user => {
                            stage2.insertAdjacentHTML('beforeend', `<p>User ID ${user.id}: "${user.username}" <${user.email}></p>`);
                        });
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }*/
        })();
    }


    const onSubmitFormOld = async (values) => {
        // setContent("Generating...")
        // setLoading(true);
        setLoading(true)


        const res = await validateWordpressCredentialsService(values)
        if (res["status"] && res["status"] === "success") {
            // values.blogUrl += '/wp-json
            await setNewWordpressIntegrationService({...values, analyticsUserId: props.user.id})
            props.getUser(props.user.email);
            toastr.success('Connected!', 'You have successfully logged in to your Wordpress account');
            navigate('/integrations')
            setLoading(false)
        } else {
            toastr.error('Error!', res["message"] ? res["message"] : 'Something happened, please try again')
            setLoading(false)
        }
    }

    return (
        <div className="row mt-3">
            <div className="col-md-8 offset-md-2">
                <Box sx={{width: '100%'}}>
                    <div className="text-center mb-2">
                        <h3>Connect to Wordpress</h3>
                    </div>
                    <div className="">
                        <p>If you do not have a Password for applications, fill out the following form, if you already have one, go <a className="btn-link" onClick={() => navigate("/integrations/wordpress-login-full")}>here</a> and complete the form</p>
                    </div>
                    <Formik
                        innerRef={(fa) => fa ? setRef(fa.values) : setRef({})}
                        initialValues={INITIAL_FROM_STATE}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values => onSubmitForm(values)}>
                        <Form>
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="blogUrl"
                                                              label="Blog URL"
                                                              type="text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                {loading ?
                                    <CircularProgress size={20}/>
                                    : <ButtonWrapper>Verify</ButtonWrapper>}
                            </div>
                        </Form>
                    </Formik>

                </Box>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WordpressLogin);
