import React, { useEffect, useState } from 'react';
import { Chip, CircularProgress } from "@mui/material"; // Reutilizando componentes de MUI como en LinkedIn
import { setNewFacebookIntegrationService } from '../../services/agent-post-ai.service';
import { getUserWithEmailThunk } from '../../redux/users/users.thunk';
import { connect } from "react-redux";

const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'} 
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20} />
                ) : (
                    <img 
                        style={{ width: '35px', height: '35px' }} 
                        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" 
                        alt="Facebook Logo" 
                    />
                )
            }
            label="Facebook"
            onClick={props.onClick}
            className="py-4 w-100" 
        />
    );
}




const FacebookLoginButtonTest = (props) => {

    const [user, setUser] = useState(null);
    const [status, setStatus] = useState('unknown');
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [integrated, setIntegrated] = useState(false);

    useEffect(() => {
        const integrateFacebook = async () => {
            if (user) {
                await setNewFacebookIntegrationService({
                    "accessToken": user?.accessToken,
                    "analyticsUserId": props.user?.id
                });
                setIntegrated(true)
                props.getUser(props.user.email)
            }
        };
        if (!integrated) {
            integrateFacebook();
        }
    }, [user]);

    useEffect(() => {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: '1029802844925790',
                cookie: true,
                xfbml: true,
                version: 'v20.0'
            });

            window.FB.AppEvents.logPageView();
            console.log('PageView logged');

            window.FB.getLoginStatus(function(response) {
                console.log('FB.getLoginStatus called');
                statusChangeCallback(response);
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                console.log('SDK already loaded');
                return;
            }            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            console.log('SDK script inserted');
        }(document, 'script', 'facebook-jssdk'));
        insertScriptManually();
    }, []);

    const statusChangeCallback = (response) => {
        setStatus(response.status);
        console.log('statusChangeCallback called');
        console.log('Response:', response);

        if (response.status === 'connected') {
            console.log('User is connected');
            fetchUserData(response.authResponse);
            setIsLogin(true);

            const { accessToken, expiresIn } = response.authResponse;
            const expirationTime = new Date().getTime() + expiresIn * 1000;
            const tokenData = {
                accessToken,
                expirationTime,
                used: false 
            };
            localStorage.setItem('facebookAuthToken', JSON.stringify(tokenData));
        } else {
            setUser(null);
        }
    };

    const fetchUserData = (authResponse) => {
        // Guardar el token de acceso en localStorage
        localStorage.setItem('facebookAccessToken', authResponse.accessToken);

        // Obtener los datos del usuario
        window.FB.api('/me', { fields: 'name,email,picture' }, function(response) {
            setUser({
                name: response.name,
                email: response.email,
                picture: response.picture.data.url,
                accessToken: authResponse.accessToken
            });

            // Ahora obtener las páginas administradas por el usuario
            window.FB.api('/me/accounts', function(pageResponse) {
                if (pageResponse && pageResponse.data) {
                    const pagesData = pageResponse.data.map(page => ({
                        id: page.id,
                        name: page.name
                    }));

                    // Guardar las páginas en localStorage
                    localStorage.setItem('facebookPages', JSON.stringify(pagesData));

                    console.log('Páginas guardadas en localStorage:', pagesData);
                }
            });
        });
    };


    const insertScriptManually = () => {
        setIsSdkLoaded(true);
        if (!document.getElementById('facebook-jssdk-manual')) {
            const script = document.createElement('script');
            script.id = 'facebook-jssdk-manual';
            script.src = "//connect.facebook.net/en_GB/sdk.js";
            document.head.appendChild(script);

            script.onload = () => {
                window.FB.init({
                    appId: '1029802844925790',
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0'
                });
                window.FB.getLoginStatus(function(response) {
                    statusChangeCallback(response);
                });
            };
        }
    };




    const handleLogin = () => {
        console.log('Login button clicked');
        window.FB.login(function(response) {
            console.log('FB.login response:', response);
            props.getUser(props.user.email);
            statusChangeCallback(response);
        }, {
            scope: 'public_profile,email,pages_manage_posts,pages_show_list,business_management',
            auth_type: 'rerequest',
            return_scopes: true,
            enable_profile_selector: true,
            config_id: '3401875730106723' 
        });
    };

    const handleLogout = () => {
        window.FB.logout(function(response) {
            console.log('User logged out:', response);
            setUser(null);
            setStatus('unknown');
            setIsLogin(false);
        });
    };

    return (
        <div>
            <div id="fb-root"></div>
            {status === 'connected' && user ? (
                <div>
                    <img src={user.picture} alt="Profile" />
                    <div>✔</div>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (
                <CustomChip
                    className="py-4 w-100" 
                    onClick={handleLogin} 
                    status={status === 'loading' ? 'loading' : 'idle'} // Puedes ajustar el estado aquí
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLoginButtonTest);


/*

import React, { useEffect, useState } from 'react';
import { setNewFacebookIntegrationService } from '../../services/agent-post-ai.service';
import { getUserWithEmailThunk } from '../../redux/users/users.thunk';
import { connect } from "react-redux";
import { Chip, CircularProgress } from "@mui/material"; // Reutilizando componentes de MUI como en LinkedIn

// Reutilizamos el componente CustomChip
const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'}
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20} />
                ) : (
                    <img style={{ width: '35px', height: '35px' }} 
                         src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" 
                         alt="Facebook Logo" />
                )
            }
            label="Facebook"
            {...props}
        />
    );
}


const FacebookLoginButtonTest = (props) => {

    const [user, setUser] = useState(null);
    const [status, setStatus] = useState('unknown');
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const integrateFacebook = async () => {
            if (user) {
                await setNewFacebookIntegrationService({
                    "accessToken": user?.accessToken,
                    "analyticsUserId": props.user?.analyticsUserId
                });
            }
        };
        console.log("integrate user", user);
        integrateFacebook();
    }, [user]);

    useEffect(() => {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: '1029802844925790',
                cookie: true,
                xfbml: true,
                version: 'v20.0'
            });

            window.FB.AppEvents.logPageView();
            console.log('PageView logged');

            window.FB.getLoginStatus(function(response) {
                console.log('FB.getLoginStatus called');
                statusChangeCallback(response);
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                console.log('SDK already loaded');
                return;
            }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            console.log('SDK script inserted');
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const statusChangeCallback = (response) => {
        setStatus(response.status);
        console.log('statusChangeCallback called');
        console.log('Response:', response);

        if (response.status === 'connected') {
            console.log('User is connected');
            fetchUserData(response.authResponse);
            setIsLogin(true);

            const { accessToken, expiresIn } = response.authResponse;
            const expirationTime = new Date().getTime() + expiresIn * 1000;
            const tokenData = {
                accessToken,
                expirationTime,
                used: false 
            };

            localStorage.setItem('facebookAuthToken', JSON.stringify(tokenData));

        } else {
            console.log('User is not connected');
            setUser(null);
        }
    };

    // Actualización en fetchUserData
    const fetchUserData = (authResponse) => {
        // Guardar el token de acceso en localStorage
        localStorage.setItem('facebookAccessToken', authResponse.accessToken);

        // Obtener los datos del usuario
        window.FB.api('/me', { fields: 'name,email,picture' }, function(response) {
            setUser({
                name: response.name,
                email: response.email,
                picture: response.picture.data.url,
                accessToken: authResponse.accessToken
            });

            // Ahora obtener las páginas administradas por el usuario
            window.FB.api('/me/accounts', function(pageResponse) {
                if (pageResponse && pageResponse.data) {
                    const pagesData = pageResponse.data.map(page => ({
                        id: page.id,
                        name: page.name
                    }));

                    // Guardar las páginas en localStorage
                    localStorage.setItem('facebookPages', JSON.stringify(pagesData));

                    console.log('Páginas guardadas en localStorage:', pagesData);
                }
            });
        });
    };

    const checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
            statusChangeCallback(response);
        });
    };

    const handleLogin2 = () => {
        console.log('Login button clicked');
        window.FB.login(async function(response) {
            console.log('FB.login response:', response);
            await setNewFacebookIntegrationService({"accessToken":response.data.accessToken, "analyticsUserId": props.user.analyticsUserId});
            props.getUser(props.user.email);
            statusChangeCallback(response);
        }, { 
            scope: 'public_profile,email,pages_manage_posts,pages_show_list,business_management',
            auth_type: 'rerequest',
            return_scopes: true,
            enable_profile_selector: true,
            config_id: '3401875730106723' 
        });
    };

    const handleLogin = () => {
        console.log('Login button clicked');
        window.FB.login(function(response) {
            console.log('FB.login response:', response);
            props.getUser(props.user.email);
            statusChangeCallback(response);
        }, {
            scope: 'public_profile,email,pages_manage_posts,pages_show_list,business_management',
            auth_type: 'rerequest',
            return_scopes: true,
            enable_profile_selector: true,
            config_id: '3401875730106723' 
        });
    };

    const handleLogout = () => {
        window.FB.logout(function(response) {
            console.log('User logged out:', response);
            setUser(null);
            setStatus('unknown');
            setIsLogin(false);
        });
    };

    const insertScriptManually = () => {
        setIsSdkLoaded(true);
        if (!document.getElementById('facebook-jssdk-manual')) {
            const script = document.createElement('script');
            script.id = 'facebook-jssdk-manual';
            script.src = "//connect.facebook.net/en_GB/sdk.js";
            document.head.appendChild(script);

            script.onload = () => {
                window.FB.init({
                    appId: '1029802844925790',
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0'
                });
                window.FB.getLoginStatus(function(response) {
                    statusChangeCallback(response);
                });
            };
        }
    };

    return (
        <div>
            <div id="fb-root"></div>
            {status === 'connected' && user ? (
                <div>
                    <img src={user.picture} alt="Profile" />
                    <div>✔</div>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                    {isSdkLoaded ? (
                        <button 
                            onClick={handleLogin} 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                fontSize: '14px'
                            }}
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                                alt="Facebook Logo"
                                style={{ width: '40px', height: '40px', marginRight: '8px' }}
                            />
                            Facebook
                        </button>            
                    ) : (    
                        <button onClick={insertScriptManually} style={{ marginLeft: '10px' }}>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                                alt="Facebook Logo"
                                style={{ width: '40px', height: '40px', marginRight: '8px' }}
                            />
                            Facebook start login
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLoginButtonTest);
*/
