import React from 'react';
import { useNavigate } from 'react-router-dom';
import OldSlackDateTimePicker from './OldSlackDateTimePicker';

export function SlackDateTimePicker(props) {
    const navigate = useNavigate();
        
    return (
    <OldSlackDateTimePicker {...props} navigate={navigate}/>
  );
}

export default SlackDateTimePicker;