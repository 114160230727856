import {connect} from "react-redux";
import {Badge, Chip, CircularProgress, Tooltip} from "@mui/material";
import {Cloud} from "@mui/icons-material";
import logo from '../../../assets/Wordpress_Blue_logo.png';
import {useNavigate} from "react-router-dom";
import {disconnectWordpressIntegrationService} from "../../../services/agent-post-ai.service";
import {getUserWithEmailThunk} from "../../../redux/users/users.thunk";
import {useState} from "react";
const CustomChip = (props) => {
    return (<Chip variant="filled"
                  disabled={props.status === 'loading'}
                  avatar={props.status === 'loading' ? <CircularProgress size={20}/> : <img style={{width: '35px', height: '35px'}} src={logo} />}
                  label="WORDPRESS"
                  {...props}/>)
}

const WordpressButton = (props) => {
    const {} = props;
    const navigate = useNavigate();
    const [status, setStatus] = useState("initial")

    const existWordpressAccount = () => {
        return props.user.analytics_wordpress_integrations !== null && props.user.analytics_wordpress_integrations?.length > 0
    }

    const disconnect = async () => {
        if (window.confirm(`Are you sure you want to disconnect your Wordpress account ${props.user.analytics_wordpress_integrations[0]["username"]}?`)) {
            setStatus("loading")
            await disconnectWordpressIntegrationService(props.user.id);
            await props.getUser(props.user.email);
            setStatus("loaded")
        }
    }

    return (
        <Tooltip
            title={existWordpressAccount() ? props.user.analytics_wordpress_integrations[0]["username"] : ''} className="w-100">
            <Badge color="success" variant="dot" invisible={!existWordpressAccount()}>
                {existWordpressAccount() ?
                    <CustomChip label={<small className="text-black-50">Connected</small>}
                                className="py-4 w-100 justify-content-center"
                                onClick={()=> {}}
                                onDelete={() => disconnect()}
                                status={status}
                /> : <CustomChip className="py-4 w-100"
                                 onClick={()=> {navigate('/integrations/wordpress-login')}}
                                 status={status}
                    />}
            </Badge>
        </Tooltip>)
}


const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WordpressButton);
