import axios from "axios";

export async function setNewWordpressIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/wordpress/newIntegration`, obj);
    return data;
}

export async function disconnectWordpressIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/wordpress/disconnect/${user_id}`);
    return data;
}

export async function validateWordpressCredentialsService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/validate-wordpress-credentials`, obj);
    return data;
}

export async function validateWordpressBlogUrlService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/validate-wordpress-blogurl`, obj);
    return data;
}

export async function setNewFacebookIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/facebook/newIntegration`, obj);
    return data;
}

export async function setNewLinkedinIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/linkedin/newIntegration`, obj);
    return data;
}

export async function disconnectLinkedinIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/linkedin/disconnect/${user_id}`);
    return data;
}

export async function sendPostNowService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/send_post_now`, obj);
    return data;
}

/** SCHEDULES*/

export async function setNewScheduleService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/api/orchestrators/NewSchedule`, obj);
    return data;
}

export async function deleteScheduleWebhookService(scheduleId, reason) {
    return await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/runtime/webhooks/durabletask/instances/${scheduleId}/terminate?reason=${reason}&taskHub=postaischedule&connection=Storage&code=gpY0wNqR1DAMKyH7IKk5daB-n07r7XMp3RD0s57g7H52AzFuBEtFTw==`);
}

export const addSchedulePostAIService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedule/newSchedule`, obj);
    return data;
}

export const getAllPostaisWithScheduleService = async (userId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai/allPosts/${userId}`);
    return data;
}

export const editSchedulePostAIService = async (id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedule/editSchedule/${id}`, {
        state: false
    });
    return data;
}

export const deleteSchedulePostAIService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedules/deleteSchedule/${id}`);
    return data;
}

