export const countWords = (text, lng) => {
    if (text) {
        switch (lng) {
            case 'ja':
                return text.match(/[\w\p{scx=Han}]+/gu).length;
            default:
                return text.split(' ').length;
        }
    } else {
        return 0;
    }
}

export function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}
