import PostaiActionTypes from "./post-ai.types";

//ACTION CREATORS

export const changeStatus = (payload) => ({
    type: PostaiActionTypes.CHANGE_STATUS,
    payload,
});

export const addNewPostai = (payload) => ({
    type: PostaiActionTypes.ADD_NEW_POSTAI,
    payload,
});

export const addJSONCardtoPostai = (payload) => ({
    type: PostaiActionTypes.ADD_JSON_CARD_TO_POSTAI,
    payload,
});

export const getAllPostai = (payload) => ({
    type: PostaiActionTypes.GET_ALL_POSTAIS,
    payload,
});

export const addJSONtoPostai = (payload) => ({
    type: PostaiActionTypes.ADD_JSON_TO_POSTAI,
    payload,
});

export const addSchedule = (payload) => ({
    type: PostaiActionTypes.ADD_SCHEDULE,
    payload,
});

export const getAllPostaisWithSchedule = (payload) => ({
    type: PostaiActionTypes.GET_ALL_POSTAIS_WITH_SCHEDULE,
    payload,
})

export const deleteSchedule = () => ({
    type: PostaiActionTypes.DELETE_SCHEDULE,
});