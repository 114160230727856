import {connect, useSelector} from "react-redux";

export const usePostAiPlarformGuard = (platform) => {
    const user = useSelector(state => state.users.user)
    switch (platform) {
        case 'wordpress':return !!user?.analytics_wordpress_integrations?.length;
        case 'linkedin': return !!user?.analytics_linkedin_integrations?.length;
        case 'facebook': return !!user?.analytics_facebook_integrations?.length;
        default: return false;
    }
}
