import * as React from 'react';
import Box from '@mui/material/Box';
import {CircularProgress} from "@mui/material";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {
    setNewWordpressIntegrationService,
    validateWordpressCredentialsService
} from "../../../services/agent-post-ai.service";
import {getUserWithEmailThunk} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

const steps = ['Connect Wordpress'];

const WordpressLoginFull = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [loading, setLoading] = React.useState(false);
    const [ref, setRef] = React.useState({});
    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const INITIAL_FROM_STATE = {
        blogUrl: '',
        username: '',
        password: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        //topic: Yup.mixed().required('The Topic is required'),
        blogUrl: Yup.string().url('Blog URL must be a valid url').required('Blog URL is required'),
        username: Yup.mixed().required('Username is required'),
        password: Yup.mixed().required('Password is required')
    });

    const onSubmitForm = async (values) => {
        // setContent("Generating...")
        // setLoading(true);
        setLoading(true)

        const res = await validateWordpressCredentialsService(values)
        if (res["status"] && res["status"] === "success") {
            // values.blogUrl += '/wp-json
            await setNewWordpressIntegrationService({...values, analyticsUserId: props.user.id})
            props.getUser(props.user.email);
            toastr.success('Connected!', 'You have successfully logged in to your Wordpress account');
            navigate('/integrations')
            setLoading(false)
        } else {
            toastr.error('Error!', res["message"] ? res["message"] : 'Something happened, please try again')
            setLoading(false)
        }
    }

    return (
        <div className="row mt-3">
            <div className="col-md-8 offset-md-2">
                <Box sx={{width: '100%'}}>
                    <div className="text-center mb-2">
                        <h3>Connect to Wordpress</h3>
                    </div>
                    <div className="">
                        {/*<p>If you have two-step authentication enabled, generate a password <a href="https://ift.tt/wordpress-2fa-activation" target="_blank">here</a>. If you are having issues with your WordPress blog, please contact us.</p>*/}
                    </div>
                    <Formik
                        innerRef={(fa) => fa ? setRef(fa.values) : setRef({})}
                        initialValues={INITIAL_FROM_STATE}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values => onSubmitForm(values)}>
                        <Form>
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="blogUrl"
                                                              label="Blog URL"
                                                              type="text"/>
                                        </div>
                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="username"
                                                              label="Username"
                                                              type="text"/>
                                        </div>
                                        <div className="form-group my-2">
                                            <TextFieldWrapper name="password"
                                                              label="Password"
                                                              type="password"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                {loading ?
                                    <CircularProgress size={20}/>
                                    : <ButtonWrapper>Connect</ButtonWrapper>}
                            </div>
                        </Form>
                    </Formik>

                </Box>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WordpressLoginFull);
