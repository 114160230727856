import {
    Button,
    ButtonGroup, capitalize,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    CircularProgress,
    Typography
} from "@mui/material";
import {ScheduleSend, Send} from "@mui/icons-material";
import SlackDateTimePicker from "./schedule-slack/SlackDateTimePicker";
import {truncateString} from "../../../helpers/text";
import {sendPostNowService} from "../../../services/agent-post-ai.service";
import {useState} from "react";
import {toastr} from "react-redux-toastr";
import { useNavigate } from 'react-router-dom'
;import Lottie from 'react-lottie'
import animationData from './stars.json'

const FinalCard = (props) => {
    const {post, credentials} = props;
    const [sending, setSending] = useState(false);

    const navigate = useNavigate();

    const sendPost = async () => {
        console.log("sending post");
        setSending(true);
        let obj = { post_id: post.id };
        try {
          await sendPostNowService(obj);
          toastr.success("Success", `Content successfully published on ${capitalize(post.platform)}`);
          setTimeout(() => {
            // Redirige a la ruta deseada
            navigate('/stored-post');
          }, 2500); // Esperar 2.5 segundos
        } catch (error) {
          console.error('Error al enviar el post:', error);
          toastr.error('Error', 'Hubo un problema al publicar el contenido');
        } finally {
          setSending(false);
        } 
      };
      
      const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }  

    return (
        <Card className="col-md-8 offset-md-2 my-3">
            <CardHeader title={post.title} subheader={<span><b>Platform: </b>{capitalize(post.platform)}</span>}/>
            <CardMedia
                component="img"
                height="320"
                image={post.image}
                alt="post image"
            />
            <CardContent>
                <Typography variant="body1" style={{
                    whiteSpace: 'pre-line'
                }} dangerouslySetInnerHTML={{__html: truncateString(post.content, 100)}}/>
                <ButtonGroup disableElevation variant="text" className="w-100 my-3">
                    <Button className="w-100 mx-0 br-none-first flex-wrap border-end"
                            disabled={sending}
                            onClick={() => sendPost()}>

                        {sending ? <Lottie options={defaultOptions} height={60} width={60}/> : <Send className="w-100 fs-1" color="primary"/>}
                        <Typography sx={{display: {xs: 'none', md: 'inline'}}}>Send Post Now</Typography>
                    </Button>
                    <SlackDateTimePicker postId={post.id}
                                         post={post}
                                         credentials={props.credentials}
                                         className="w-100 mx-0 br-none flex-wrap">
                                    <span>
                                        <ScheduleSend className="w-100 fs-1" color="secondary"/>
                                        <Typography
                                            sx={{display: {xs: 'none', md: 'inline'}}}>Schedule sending</Typography>
                                    </span>
                    </SlackDateTimePicker>
                </ButtonGroup>
            </CardContent>
        </Card>
    )
}

export default FinalCard;
