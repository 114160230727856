import {
    addSchedulePostAIService, editSchedulePostAIService,
    getAllPostaisWithScheduleService

} from "../../services/agent-post-ai.service";
import {
    addSchedule,
    changeStatus,
    deleteSchedule,
    getAllPostaisWithSchedule, 
} from "./post-ai.actions";
import { toastr } from "react-redux-toastr";

export const addScheduleThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addSchedulePostAIService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addSchedule(data));
        } catch (error) {
            console.error(error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };
};

export const getAllPostaisWithScheduleThunk = (userId) => {
    return async (dispatch) => {
        dispatch(changeStatus('loading'));
        try {
            const data = await getAllPostaisWithScheduleService(userId);
            console.log("data", data);
            if (!data.error) {
                dispatch(getAllPostaisWithSchedule(data));
                dispatch(changeStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };
};

//not deleting, but updating
export const deleteScheduleThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await editSchedulePostAIService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteSchedule());
        } catch (error) {
            console.error(error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };
};













