//THUNKS

import {getBarDashboardService, getDashboardService} from "../../services/dashboard.service";
import {getBarDashboard, getDashboard} from "./dashboard.actions";
import {toastr} from "react-redux-toastr";

export const getDashboardThunk = (tenantId) => {
    return async (dispatch) => {
        try {
            const data = await getDashboardService(tenantId);
            console.log("data getDash", data);
            if (!data.error) dispatch(getDashboard(data));
        } catch (error) {
            console.error(error);
            toastr.error('Request error', 'Please login again');
        }
    };
};

export const getBarDashboardThunk = (tenantId) => {
    return async (dispatch) => {
        try {
            const data = await getBarDashboardService(tenantId);
            console.log("data getBarDash", data);
            if (!data.error) dispatch(getBarDashboard(data));
        } catch (error) {
            console.error(error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };
};
