import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

const MenuDrawerItem = (props) => {
    const navigate = useNavigate();
    const {index, item, openFunc} = props;
    const [show, setShow] = useState(item.show);

    const onRoute = (url) => {
        navigate(url);
//         handleDrawerToggle();

    }

    return (
        show && <ListItem key={item.name} disablePadding sx={{display: 'block'}}>
            <ListItemButton
                onClick={() => onRoute(item.routerLink)}
                sx={{
                    minHeight: 48,
                    justifyContent: openFunc() ? 'initial' : 'center',
                    px: 2.5,
                }}>
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: openFunc() ? 3 : 'auto',
                        justifyContent: 'center',
                        opacity: {xs: openFunc() ? 1 : 0, sm: 1}
                    }}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} sx={{opacity: openFunc() ? 1 : 0}}/>
            </ListItemButton>
        </ListItem>
    )
}

export default MenuDrawerItem;
