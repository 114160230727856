const PostaiActionTypes = {
    ADD_NEW_POSTAI: "ADD_NEW_POSTAI",
    ADD_JSON_CARD_TO_POSTAI: "ADD_JSON_CARD_TO_POSTAI",
    GET_ALL_POSTAIS: "GET_ALL_POSTAIS",
    ADD_JSON_TO_POSTAI: "ADD_JSON_TO_POSTAI",
    ADD_SCHEDULE: "ADD_SCHEDULE",
    GET_ALL_POSTAIS_WITH_SCHEDULE: "GET_ALL_POSTAIS_WITH_SCHEDULE",
    DELETE_SCHEDULE: "DELETE_SCHEDULE",
    CHANGE_STATUS: "CHANGE_STATUS",
  };
  
  export default PostaiActionTypes;
  