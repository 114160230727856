import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SurveySlackCard from "./survey-slack-card/survey-card";
//import { getAllPostsWithScheduleService } from "../../../services/agent-post-ai.service";
import { getAllPostaisWithScheduleThunk } from "../../../redux/post-ai/post-ai.thunk";
import { toastr } from "react-redux-toastr";

/*
const status = "loaded";
const surveys = [
    {
        id: "id1",
        title: "title",
        description: "description",
        json: "",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
    },
    {
        id: "id2",
        title: "title",
        description: "description",
        json: "",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
    },
];
*/

const StoredPosts = (props) => {
    const { user } = props
    const [status, setStatus] = useState("not-loaded");
    const [data, setData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                setStatus("loading");
                //const data = await getAllPostsWithScheduleService(user.id);
                props.getPostais(user.id)
                console.log("DATA:", data);
                setData(data);
            } catch (error) {
                console.error(error);
                toastr.error(`Something went wrong, please reload the page to try again`);
            } finally {
                setStatus("loaded");
            }
        })();
    }, []);

    return <SurveySlackCard status={status} surveys={props.postais} />;
};

// export default StoredPosts;

const mapStateToProps = (state) => {
    console.log("state app:", state);
    return {
        user: state.users.user,
        postais: state.postais.allPostais
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPostais: (userId) => dispatch(getAllPostaisWithScheduleThunk(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredPosts);
