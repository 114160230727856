import {AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate} from "@azure/msal-react";
import {apiCall} from "../../../../../apiCall";
import {models, Qna} from "powerbi-client";
import {toFormData} from "../../../../../helpers/toFormData";
import axios from "axios";
import {loginRequest} from "../../../../../azure_auth/authConfig";
import RotatingSpinner from "../../../../layouts/RotatingSpinner";
import {PowerBIEmbed} from "powerbi-client-react";
import React from "react";
import {Component} from "react";
import {connect} from "react-redux";
import styles from "../analysis-panel/analysis-panel.module.css";
import {IconButton} from "@mui/material";
import {Close, Print} from "@mui/icons-material";
import {Approaches} from "../../../models";
import UserChatMessage from "../user-chat-message/user-chat-message";
import { stripHtml } from "string-strip-html";
import {toastr} from "react-redux-toastr";
import html2canvas from "html2canvas";

class PbiQaIndex extends Component {
    state = {
        id: null,
        config: null,
        loading: true,
        loadingMessage: 'Loading...',
        accessToken: null,
        style: "report-loading",
        refresh: false,
        countToRefresh: 0,
        width: 0
    }

    constructor(props) {
        super(props);

        this.reportRef = React.createRef();
    }

    static contextType = MsalContext;

    async componentDidMount() {

        console.log("report mount");
        const content = document.getElementById(`answer-container-${this.props.index}`);
        console.log("content", content.clientWidth, content.clientHeight);
        this.setState({width: content.clientWidth})
        try {

            /* const history = this.props.answers.map((a) => ({ user: a[0], bot: a[1].answer }))
            const request = {
                history: [...history, { user: this.props.question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    prompt_template: undefined,
                    exclude_category: undefined,
                    top: 3,
                    semantic_ranker: false,
                    semantic_captions: false,
                    suggest_followup_questions: true,
                },
                analytics_user_id: this.props.user.whitelisted
                    ? this.props.user.id
                    : 'empty-data',
                //analytics_user_id: 'hr-index-json-2',
                index_name: this.props.indexName,
            }*/

            try {

                let res = await apiCall(`${process.env.REACT_APP_DASHBOARD_URL}/api/getEmbedToken`, '75aea719-1c91-4212-a27b-23861c3263aa');
                // let res = await apiCall(`${process.env.REACT_APP_DASHBOARD_URL}/api/getEmbedToken`, 'e4c40041-2a9d-43a8-be02-83949fb1fec3');
                console.log("PBI", res)
                console.log("query", this.props)

                /**For Q&A report*/
                // const {data} = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/get-pbi-search`, request);
                // console.log("Query", data);

                let config = {
                    accessToken: res.accessToken,
                    // id: res.embedUrl[0].reportId,
                        datasetIds: ['e4c40041-2a9d-43a8-be02-83949fb1fec3'],
                    // embedUrl: res.embedUrl[0].embedUrl,
                    embedUrl: "https://app.powerbi.com/qnaEmbed",
                    // question: data.search_query || 'how many taquiña',
                    question: this.props.query || 'how many taquiña',
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                    type: 'qna', // Supported types: report, dashboard, tile, visual, and qna.
                    viewMode: models.QnaMode.ResultOnly

                };

                /*if (window.innerWidth < window.innerHeight) {
                    console.log("MOBILE")
                    config.settings.layoutType = models.LayoutType.MobilePortrait
                    delete config.settings.customLayout;
                }*/
                this.setState({config, loadingMessage: 'Rendering...', loading: false});
            } catch (e) {
                toastr.error('Something unexpected happened, please try again');
                this.props.setClose();
                this.setState({loading: false});
            }
        } catch (error) {
            console.error(error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    }

    // componentDidMount(){
    //   this.setState
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.warn("STATE: ", this.state, this.props.showFollowupQuestions)

        /*if (!this.props.showFollowupQuestions && this.state.countToRefresh < 1) {
            this.setState({countToRefresh: 1, loadingMessage: 'Rendering...', loading: true, style: "report-loading"});
            // console.warn("REFRESH: ", this.state.loading)
            setTimeout(() => {
                this.setState({loading: false})
            }, 500)
        }*/
        console.log("WIDTH", this.state.width, this.props.showFollowupQuestions);
        if (!this.props.showFollowupQuestions && this.state.countToRefresh < 1) {
            this.setState({countToRefresh: 1});
            const container = document.getElementById(`answer-container-${this.props.index}`);
            console.log("WIDTH SW", container, this.state.width);
            container.style.width = this.state.width + "px";

        }

    }

    setRendered = async (event) => {
        console.log('Report loaded');
        this.setState({style: "report-style-class", loadingMessage: 'rendered'});
        // const tenantId = this.context.accounts[0].tenantId;

        console.log('WND', window.report, event);
        // console.log('WND 2', window.report.getDefaultEmbedUrlEndpoint(), window.report.getId());

        const body = event.target.querySelector("iframe");
        // console.log("EV", body)

        /*html2canvas(body).then(function(canvas) {
            // `canvas` es el lienzo que contiene la imagen del iframe
            console.log("CANVAS", canvas)
            // Convierte el lienzo a una URL de imagen
            const imageUrl = canvas.toDataURL('image/png');

            // Haz lo que desees con la URL de la imagen, como mostrarla o guardarla
            const imageElement = document.createElement('img');
            imageElement.src = imageUrl;

            console.log(imageElement);
            // document.body.appendChild(imageElement);
        });*/

        // console.log("body1", body.contentDocument);
        // console.log("body2", body.contentWindow.document);

        /*const text = stripHtml(body).result;
        console.log("result", text);*/

        /*let activePage = await window.report.getActivePage();
        const filters = await activePage.getFilters();
        console.log("FILTERS", filters);

        console.log(activePage);*/
    }

    capturarVisual1 = () => {
        // Obtén acceso a la pantalla del usuario
        navigator.mediaDevices.getDisplayMedia({ video: true })
            .then(function(stream) {
                const displayMediaStream = stream;

                // Crea un elemento de video para mostrar el contenido de la pantalla
                const videoElement = document.createElement('video');
                videoElement.srcObject = displayMediaStream;

                videoElement.onloadedmetadata = function() {
                    // Crea un elemento canvas para capturar la imagen
                    const canvasElement = document.createElement('canvas');
                    canvasElement.width = videoElement.videoWidth;
                    canvasElement.height = videoElement.videoHeight;

                    // Dibuja el contenido del video en el canvas
                    const context = canvasElement.getContext('2d');
                    context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                    // Convierte el canvas a una imagen base64
                    const imageDataUrl = canvasElement.toDataURL('image/png');

                    // Puedes mostrar la imagen o guardarla como desees
                    const imgElement = document.createElement('img');
                    imgElement.src = imageDataUrl;
                    document.body.appendChild(imgElement);

                    // Detén el acceso a la pantalla
                    displayMediaStream.getTracks().forEach(track => track.stop());
                };

                videoElement.play();
            })
            .catch(function(error) {
                console.error('Error al acceder a la pantalla:', error);
                toastr.error(`Something went wrong, please reload the page to try again`);
            });

    };

    capturarVisual = () => {
        const elementToCapture = document.getElementById("report-container-" + this.props.index);
        // Capturar la captura de pantalla
        html2canvas(elementToCapture).then((canvas) => {
            // Convertir el resultado en una imagen o archivo PDF
            const screenshotUrl = canvas.toDataURL('image/png'); // O 'image/jpeg' para JPEG, 'application/pdf' para PDF, etc.

            console.log("REPORT", elementToCapture)
            // Puedes mostrar la imagen en tu aplicación o proporcionar un enlace para descargarla.
        });
    }

    render() {

        // console.log(this.props);
        return (
            <div className="">
                {/*<UserChatMessage message={this.props.question} />
                <div className={styles.closeBtn}>
                    <IconButton
                        className="bg-white"
                        color="error"
                        onClick={() => this.capturarVisual()}
                    >
                        <Print />
                    </IconButton>
                </div>*/}
                <AuthenticatedTemplate>

                    {this.state.loadingMessage !== 'rendered' && <div className="d-flex justify-content-center">
                        <div className="text-center">
                            <p>{this.state.loadingMessage}</p>
                            <RotatingSpinner/>
                        </div>
                    </div>}

                    {!this.state.loading &&
                        <div ref={this.reportRef} id={"report-container-" + this.props.index}>
                        <PowerBIEmbed
                            embedConfig={this.state.config
                            }

                            eventHandlers={new Map([
                                ['loaded', function () {
                                    console.log('Report loaded');
                                }],
                                ['rendered', function () {
                                    console.log('Report rendered');
                                }],
                                ['visualRendered', this.setRendered],
                                ['dataSelected', function (event) {
                                    console.log("Data SEleted", event)
                                }],
                                ['error', function (event) {
                                    console.log(event.detail);
                                    window.report.setQuestion(this.props.query);
                                }]
                            ])
                            }

                            cssClassName={
                                this.state.style
                            }

                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    console.log("EMBEDEDDDDDDDD", embeddedReport)
                                    window.report = embeddedReport;
                                }
                            }

                        />
                        </div>
                    }

                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        indexName: state.agentHrs.index,
        containerName: state.agentHrs.container
        // allSurveys: state.surveys.allSurveys,
        // singleSurvey: state.surveys.singleSurvey,

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        // getSingleSurvey: (id) => dispatch(getSingleSurveysWithQandAThunk(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PbiQaIndex);
