import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    capitalize,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Snackbar,
    Tooltip,
    Typography
} from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { InsertChartOutlined, MoreVert, PieChartOutline, Telegram, Visibility } from "@mui/icons-material";
import React, { useState } from "react";
import { format } from "date-fns";
import { ScheduleSend, Send } from "@mui/icons-material";
import { Link } from "react-router-dom";
import './survey-card.css';
import SlackDateTimePicker from "../schedule-slack/SlackDateTimePicker";
import DisplaySchedule from "../schedule-slack/DisplaySchedule";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { sendPostNowService } from "../../../../services/agent-post-ai.service";
import Lottie from 'react-lottie'
import animationData from '../stars.json'
import { usePostAiPlarformGuard } from "../../../../guards/post-ai-platform-guard";

const createOptions = (name, routerLink, translation) => {
    return {
        name,
        routerLink,
        translation
    }
}

const SurveySlackCardItem = (props) => {
    const { t } = useTranslation();

    const options = [
        /*createOptions('Send a copy', ''),
        createOptions('Transfer', ''),
        createOptions('Make a copy', ''),
        createOptions('Create template', ''),
        createOptions('Clear response', ''),*/
        createOptions('Delete', '', t('surveys.delete')),
    ]

    const { item } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(item.active);
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const { post, credentials } = props;

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (option = '') => {
        setAnchorEl(null);
    }

    const getActiveSchedule = (schedules) => {
        console.log('schedules:', schedules)
        if (schedules.length > 0) {
            for (let schedule of schedules) {
                if (schedule.state === true) {
                    return schedule;
                }
            }
        }
        return false;
    }
    const sendPost = async () => {
        console.log("sending post")
        setSending(true)
        let obj = { post_id: item.id }
        await sendPostNowService(obj)
        toastr.success("Success", `Content successfully published on ${capitalize(item.platform)}`)
        setSending(false)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }


    return (
        <div className="h-100">
            <Card className="h-100 position-relative" style={{ borderRadius: '8px', padding: '10px' }}>
                <CardHeader
                    action={
                        <div>
                            {/*<IconButton onClick={handleOpenMenu}><MoreVert/></IconButton>*/}
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                {options.map((option, index) =>
                                    <MenuItem key={index}
                                        onClick={() => handleCloseMenu(option.name)}>{option.translation}</MenuItem>)}
                            </Menu>
                            {item.posted === true &&
                                <div style={{ padding: '15px', color: 'green', }}>
                                    <TaskAltIcon />
                                    <Typography style={{ fontSize: '10px', color: 'green' }}>Posted</Typography>
                                </div>
                            }
                        </div>
                    }
                    title={item.title}
                    subheader={item.description} />
                <CardContent>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {'Platform'}: <b>{item.platform}</b>
                    </Typography>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('Created')}: <b>{format(new Date(item.createdAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    <Typography gutterBottom component="div" color="text.secondary">
                        {t('Modified')}: <b>{format(new Date(item.updatedAt), 'MM/dd/yyyy')}</b>
                    </Typography>
                    {/*<Typography gutterBottom variant="body2" color="text.info">
                        Questions: <b>{numberOfQuestions(item)}</b>
                    </Typography>*/}
                    {item["analytics_post_ai_schedules"] && getActiveSchedule(item["analytics_post_ai_schedules"]) !== false && (
                        <DisplaySchedule
                            schedule={getActiveSchedule(item["analytics_post_ai_schedules"])}
                        />
                    )}
                </CardContent>
            </Card>
            <div className="card-action-bottom border-top">
                <div className="d-flex justify-content-evenly animateHover">
                    <Tooltip title={t('Send post')}>
                        <IconButton disabled={!usePostAiPlarformGuard(item.platform) || sending}
                            onClick={() => sendPost()}>
                            {sending ? <Lottie options={defaultOptions} height={40} width={40} /> : <Telegram />}
                        </IconButton>
                    </Tooltip>
                    {/*<Button className="w-100 mx-0 br-none-first flex-wrap border-end"
                            disabled={sending}
                            onClick={() => sendPost()}>

                        {sending ? <CircularProgress size={30}/> : <Send className="w-100 fs-1" color="primary"/>}
                        <Typography sx={{display: {xs: 'none', md: 'inline'}}}>Send Post Now</Typography>
                    </Button>*/}

                    {usePostAiPlarformGuard(item.platform) && (getActiveSchedule(item["analytics_post_ai_schedules"]) === false ?
                        <SlackDateTimePicker surveyId={item.id} post={item} /> :
                        <div>
                            <SlackDateTimePicker surveyId={item.id} post={item}
                                days={getActiveSchedule(item["analytics_post_ai_schedules"]).days}
                                hours={getActiveSchedule(item["analytics_post_ai_schedules"]).hours}
                                minutes={getActiveSchedule(item["analytics_post_ai_schedules"]).minutes}
                                scheduleId={getActiveSchedule(item["analytics_post_ai_schedules"]).id} />
                        </div>)}
                </div>
            </div>
            <div>
                <Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={() => setOpen(false)}>
                    {status ?
                        <Alert severity="success" sx={{ width: '100%' }} onClose={() => setOpen(false)}>
                            {t('item.play')}
                        </Alert> :
                        <Alert severity="warning" sx={{ width: '100%' }} onClose={() => setOpen(false)}>
                            {t('item.pause')}
                        </Alert>
                    }

                </Snackbar>
            </div>
        </div>
    )
}

export default SurveySlackCardItem;
