import { combineReducers } from "redux";

//import here
import dashboardReducer from "./dashboard/dashboard.reducer";
import userReducer from "./users/users.reducer";
import {reducer as toastrReducer} from 'react-redux-toastr'
import contentReducer from "./content/content.reducer";
import agentHrReducer from "./agent-hr/agent-hr.reducer";
import PostaisReducer from "./post-ai/post-ai.reducer";


const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  users: userReducer,
  contents: contentReducer,
  agentHrs: agentHrReducer,
  postais: PostaisReducer,
  toastr: toastrReducer
});

export default rootReducer;
